import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from '~/components'
import * as st from '~/assets/styl/FiftyFifty.module.styl'
import cn from 'classnames'

interface Props {
  container: Array<{
    bkgColor: string
    title: string
    text?: string
    flexList?: boolean
    listItems?: Array<string>
    hasBtn?: boolean
    hasHeader?: boolean
    btnTitle?: string
    btnHref?: string
    imgSrc: string
    imgAlt: string
    imgSrcMobile: string
  }>
}

const FiftyFiftyTwo = ({ container }: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  const renderTitle = (title: string) => {
    return <span dangerouslySetInnerHTML={{ __html: title }} />
  }

  return (
    <div className={st.core}>
      {container.map(
        (
          {
            bkgColor,
            title,
            text,
            flexList,
            listItems,
            hasBtn,
            hasHeader,
            btnTitle,
            btnHref,
            imgSrc,
            imgAlt,
            imgSrcMobile,
          },
          index
        ) => (
          <div key={index}>
            <figure>
              {!isMobile ? (
                <img src={imgSrc} alt={imgAlt} />
              ) : (
                <img src={imgSrcMobile} alt={imgAlt} />
              )}
            </figure>
            <div style={{ backgroundColor: bkgColor }}>
              <section className="color-white">
                {hasHeader && (
                  <span className="uppercase bkg-green-rounded little-size color-white">
                    <strong>Lotes de 450 a 600m²</strong>
                  </span>
                )}
                <h2 className={cn('title', hasBtn && st.customMargin)}>
                  {renderTitle(title)}
                </h2>
                {text && <p>{text}</p>}
                {listItems && (
                  <ul className={flexList && st.flexList}>
                    {listItems.map((item, index) => (
                      <li key={index} className="text">
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
                {hasBtn && (
                  <Link href={btnHref} className="btn btn-orange">
                    {btnTitle}
                  </Link>
                )}
              </section>
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default FiftyFiftyTwo
