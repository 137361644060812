import React, { useState } from 'react'
import Slider from '@fil1pe/react-slider'
import { Lightbox } from '~/components'
import { Image } from '~/components/Lightbox'
import imgSlider01 from '~/assets/img/img-slider-01.png'
import imgSlider02 from '~/assets/img/img-slider-02.png'
import imgSlider03 from '~/assets/img/img-slider-03.png'
import imgSlider04 from '~/assets/img/img-slider-04.png'
import imgSlider05 from '~/assets/img/img-slider-05.png'
import imgSlider06 from '~/assets/img/img-slider-06.png'
import imgSlider07 from '~/assets/img/img-slider-07.png'
import imgSlider08 from '~/assets/img/img-slider-08.jpg'
import imgSlider09 from '~/assets/img/img-slider-09.png'

const imgsList = [
  {
    url: imgSlider01,
    legend: '',
  },
  {
    url: imgSlider02,
    legend: '',
  },
  {
    url: imgSlider03,
    legend: '',
  },
  {
    url: imgSlider04,
    legend: '',
  },
  {
    url: imgSlider05,
    legend: '',
  },
  {
    url: imgSlider06,
    legend: '',
  },
  {
    url: imgSlider07,
    legend: '',
  },
  {
    url: imgSlider08,
    legend: '',
  },
  {
    url: imgSlider09,
    legend: '',
  },
]

const SliderWithZoom = () => {
  const [index, setIndex] = useState(0)
  const [images, setImages] = useState<Array<Image>>()
  return (
    <>
      <Slider slidesToShow={1} slidesToScroll={1} className="slider">
        {imgsList.map(({ url, legend }, index) => (
          <div className="slide" key={index}>
            <img
              src={url}
              alt={legend}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIndex(index)
                setImages(imgsList.map((i) => i))
              }}
            />
          </div>
        ))}
      </Slider>
      <Lightbox
        index={index}
        images={images}
        setImages={setImages}
        setIndex={setIndex}
      />
    </>
  )
}

export default SliderWithZoom
