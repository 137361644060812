import React, { useEffect, useState } from 'react'
import { Link } from '~/components'
import { useMediaQuery } from 'react-responsive'
import logo from '~/assets/svg/logo-green.svg'
import * as st from '~/assets/styl/Header.module.styl'
import cn from 'classnames'
const navItems = [
  {
    href: '#topo',
    title: 'Home',
  },
  {
    href: '#surpreenda-se',
    title: 'O loteamento',
  },
  {
    href: '#projeto',
    title: 'Projeto Urbanístico',
  },
  {
    href: '#localizacao',
    title: 'Localização',
  },
  {
    href: '#fale-conosco',
    title: 'Fale Conosco',
  },
]

const Header = () => {
  const [visible, setVisible] = useState(false)
  const [activeNavMobile, setActiveNavMobile] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  useEffect(() => {
    const bottomPosition = isMobile ? 340 : window.innerHeight
    const onScroll = () => {
      setVisible(window.scrollY >= bottomPosition)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  })
  return (
    <header
      className={cn(
        st.core,
        visible && st.visible,
        activeNavMobile && st.customHeight
      )}
    >
      <div className="container">
        <img
          src={logo}
          alt="Logo do empreendimento Marina Sunshine - Open city Campeche"
        />
        <nav className={activeNavMobile && st.navVisibleMobile}>
          <ul>
            {navItems.map(({ href, title }, index) => (
              <li key={index} onClick={() => setActiveNavMobile(false)}>
                <Link href={href}>{title}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <div>
          <Link
            href="https://www.instagram.com/fmendonca_empreendimentos/"
            external
            className={st.insta}
          ></Link>
          <button
            className={cn(
              st.openMenuMobile,
              activeNavMobile && st.navVisibleMobile
            )}
            onClick={() => setActiveNavMobile(!activeNavMobile)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
